import React from 'react'
import ImageMeta from '../ImageMeta';
const BestMtPleasant = () => {
    return (
        <div className="bmp-banner">
            <div className="img-text-grid">
                <ImageMeta
                    publicId="OSSC/DEV/best-of-mount-pleasant-banner-image"
                    cloudName="nuvolum"
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                    className="mp-badge"
                />
                <div>
                    <h3>Recognized by Mount Pleasant Magazine in 2017, 2018, 2020, and 2021</h3>
                    <p>Oral Surgery & Dental Implant Specialists South Carolina would like to thank our patients, our community, and Mount Pleasant Magazine for recognizing our practice as a “Best of Mount Pleasant 2021" winner. We are honored to provide exemplary care and service to all of our patients and referring providers.</p>
                </div>
            </div>

            <ImageMeta
                publicId="OSSC/DEV/best-of-mount-pleasant-banner-logo"
                cloudName="nuvolum"
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
                className="bmp-background-logo"
            />
            
        </div>
    )
}

export default BestMtPleasant
