import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroupMap from "../Button/ButtonGroupMap";
import CTA from "./CTA"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class GetStarted extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <CTA
        className={this.props.className ? this.props.className : ""}
        sideColumnIs={this.props.sideColumnIs}
        headingColumns={
          <div className="column">
            <h4>{this.props.subheading}</h4>
            <h3>{this.props.heading}</h3>
          </div>
        }
        textColumns={
          <div className="column">
            {this.props.paragraph}
          </div>
        }
        buttonColumns={
          this.props.buttons ? 
          <ButtonGroupMap isCentered buttons={this.props.buttons} />
          :
          <div className="column">
            <Button
              href={this.props.buttonUrl}
              buttonText={this.props.buttonText}
            />
          </div>
        }
      />
    )
  }
}

GetStarted.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph: PropTypes.element,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
}

export default GetStarted
