import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@bit/azheng.joshua-tree.button';
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class CTA extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={`columns cta-with-button ${this.props.className ? this.props.className : ""}`}>
        <div className={`column is-${this.props.sideColumnIs}`}></div>
        <div className="column">
          <div className="columns">{this.props.headingColumns}</div>
          <div className="columns">{this.props.textColumns}</div>
          <div className="columns is-centered has-text-centered">
            {this.props.buttonColumns}
          </div>
        </div>
        <div className={`column is-${this.props.sideColumnIs}`}></div>
      </div>
    )
  }
}

CTA.propTypes = {
  headingColumns: PropTypes.element,
  textColumns: PropTypes.element,
  buttonColumns: PropTypes.element,
  sideColumnIs: PropTypes.number,
}

export default CTA
